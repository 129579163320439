import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser, faEnvelope, faPhone, faCheck, faFile,
    faCheckSquare, faPlus, faTrash, faSave, faCamera,
    faPlusCircle as faPlusCircleSolid,
    faPaperPlane as faPaperPlaneSolid,
    faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
    faBriefcase, faCompress, faDesktop, faShieldAlt, faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faBox as faBoxSolid,
    faStoreAlt as faStoreAltSolid, // NOTE: renamed to 'shop' in fontawesome 6.x
    faUserFriends as faUserFriendsSolid,
    faGlobe as faGlobeSolid,
    faCloudDownloadAlt, faUnlock,
    faFolderOpen as faFolderOpenSolid,
    faEye as faEyeSolid,
    faCog as faCogSolid,
    faChevronLeft as faChevronLeftSolid,
    faChartLine as faChartLineSolid,
    faFolder as faFolderSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft, /* faArrowRight, */
    faEllipsisV as faEllipsisVSolid,
    faCloudUploadAlt as faCloudUploadAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion as faQuestionSolid,
    faCircle as faCircleSolid,
    faCheckCircle as faCheckCircleSolid,
    faIdBadge as faIdBadgeSolid,
    faLock as faLockSolid,
    // faStar as faStarSolid,
    // faStarHalf as faStarHalfSolid,
    faExternalLinkAlt as faExternalLinkAltSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare,
    faCircle as faCircleRegular,
    faCopy as faCopyRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
    // faStar as faStarLight,
    faCopy as faCopyLight,
} from '@fortawesome/pro-light-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import {
    faEllipsisV as faEllipsisVRegular,
    faEllipsisH as faEllipsisHRegular,
    faCode as farCode,
    faExternalLinkAlt as faExternalLinkAltRegular,
    faSyncAlt as faSyncAltRegular,
    faAngleDoubleRight as faAngleDoubleRightRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
    // faWarningTriangle as faWarningTriangleSolid,
    faQuestionSquare as faQuestionSquareSolid,
    faBoxHeart as faBoxHeartSolid,
} from '@fortawesome/pro-solid-svg-icons';

function setup() {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
    library.add(faUser, faEnvelope, faPhone, faCheck, faFile, faCheckSquare, faSquare,
        faPlus, faTrash, faSave, faCamera, faBriefcase, faCompress, faDesktop, faShieldAlt,
        faPlusCircleSolid,
        faPaperPlaneSolid,
        faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
        faHeart, faBuilding, faUserSecret, faFingerprint, faCode, faBars, faUserCircle,
        faPencilAlt, faSearch, faEdit, faInfoCircle,
        faBoxSolid,
        faStoreAltSolid,
        faUserFriendsSolid,
        faGlobeSolid,
        faCloudDownloadAlt, faUnlock,
        faFolderOpenSolid,
        faEyeSolid,
        faCogSolid,
        faChevronLeftSolid,
        faChartLineSolid, faFolderSolid,
        faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
        faAngleDoubleRightRegular,
        faMinusSquare, faPaperclip, faArrowLeft, /* faArrowRight, */
        faEllipsisVSolid, faCloudUploadAltSolid, faExclamationTriangleSolid,
        faQuestionCircleSolid,
        faQuestionSquareSolid,
        faQuestionSolid,
        faBoxHeartSolid,
        faCircleSolid, faCircleRegular,
        faCheckCircleSolid,
        faIdBadgeSolid,
        faLockSolid,
        faExternalLinkAltSolid,
        faExternalLinkAltRegular,
        faSyncAltRegular,
        faCopyRegular,
        faCopyLight,
        // faStarLight, faStarSolid,
        // faStarHalfSolid,
        faEllipsisVRegular,
        faEllipsisHRegular,
        farCode);
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
