/*!
Copyright (C) 2020 Liberty Infrasystems. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function getOctetStream(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/octet-stream',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/* if this function is used, and server returns 202 Accepted with no json in the response body, axios will throw an error because of the unexpected response body; since we know the upload API returns 202 Accepted with no response body, we use postUpload instaead.
async function postUploadAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

async function postUpload(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class CurrentAccount {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}`, request, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete/account`, null, null, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/account`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Brand {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/brand`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/brand`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/brand`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/brand`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/brand`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/check/brand`, request, null, { requestHeaders: this.requestHeaders });
    }

    async publish(request) {
        return postJsonAcceptJson(`${this.url}/publish/brand`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class BrandAlias {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    /*
    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/brand-alias`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/brand-alias`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/brand-alias`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/brand-alias`, null, query, { requestHeaders: this.requestHeaders });
    }
    */

    async search(query) {
        return getJson(`${this.url}/search/brand-alias`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/brand-alias`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class BrandAttr {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/brand-attr`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/brand-attr`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/brand-attr`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/brand-attr`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/brand-attr`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/check/brand-attr`, request, null, { requestHeaders: this.requestHeaders });
    }
}

// this allows an authorized user to directly review the brand assets in the cache; to publish assets, see the other APIs for those assets
class Published {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // async delete(query) {
    //     return postJsonAcceptJson(`${this.url}/delete/published`, null, query, { requestHeaders: this.requestHeaders });
    // }

    async search(query) {
        return getJson(`${this.url}/search/published`, query, { requestHeaders: this.requestHeaders });
    }
}

class Color {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/color`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/color`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/color`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/color`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/color`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/check/color`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Domain {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/domain`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/domain`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/domain`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/domain`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/domain`, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/domain`, request, query, { requestHeaders: this.requestHeaders });
    }

    async checkWhois(query) {
        return getJson(`${this.url}/check/domain-whois`, query, { requestHeaders: this.requestHeaders });
    }

    async checkNameservers(query) {
        return getJson(`${this.url}/check/domain-nameservers`, query, { requestHeaders: this.requestHeaders });
    }
}

class DomainVerification {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/domain-verification`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/domain-verification`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/check/domain-verification`, request, null, { requestHeaders: this.requestHeaders });
    }

    /*
    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/domain-verification`, request, query, { requestHeaders: this.requestHeaders });
    }
    */

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/domain-verification`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/domain-verification`, query, { requestHeaders: this.requestHeaders });
    }
}

/*
class File {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/file`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async get(query) {
        return getJson(`${this.url}/state/file`, query, { requestHeaders: this.requestHeaders });
    }

    // async edit(query, request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/edit/file`, request, query, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/file`, null, query, { requestHeaders: this.requestHeaders });
    }

    // async search(query) {
    //     return getJson(`${this.url}/search/file`, query, { requestHeaders: this.requestHeaders });
    // }

}
*/

class Image {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/image`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/image`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/image`, request, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/image`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/image`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/image`, query, { requestHeaders: this.requestHeaders });
    }

    async download(query) {
        return getOctetStream(`${this.url}/download/image`, query, { requestHeaders: this.requestHeaders });
    }

    async upload(query, request) {
        return postUpload(`${this.url}/upload/image`, request, query, { requestHeaders: this.requestHeaders });
    }

    async publish(request) {
        return postJsonAcceptJson(`${this.url}/publish/image`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class LinkBrandImage {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/link-brand-image`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/link-brand-image`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-brand-image`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-brand-image`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-brand-image`, query, { requestHeaders: this.requestHeaders });
    }
}

class LinkAccountUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-account-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-account-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-account-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-account-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }
}

// class EmailAudience {
//     constructor(context) {
//         this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
//         this.requestHeaders = context.requestHeaders;
//     }

//     async check(id, request) {
//         return postJsonAcceptJson(`${this.url}/check/email-audience`, request, { id }, { requestHeaders: this.requestHeaders });
//     }

//     async create(request) {
//         return postJsonAcceptJson(`${this.url}/create/email-audience`, request, null, { requestHeaders: this.requestHeaders });
//     }

//     async get(id) {
//         return getJson(`${this.url}/state/email-audience`, { id }, { requestHeaders: this.requestHeaders });
//     }

//     async edit(query, request) {
//         // NOTE: you only need to specify the attributes that should be changed
//         return postJsonAcceptJson(`${this.url}/edit/email-audience`, request, query, { requestHeaders: this.requestHeaders });
//     }

//     async delete(query) {
//         // NOTE: you only need to specify the attributes that should be changed
//         return postJsonAcceptJson(`${this.url}/delete/email-audience`, null, query, { requestHeaders: this.requestHeaders });
//     }

//     async search(query) {
//         return getJson(`${this.url}/search/email-audience`, query, { requestHeaders: this.requestHeaders });
//     }
// }

class Palette {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/palette`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/palette`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/palette`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/palette`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/palette`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/palette`, request, null, { requestHeaders: this.requestHeaders });
    }

    async publish(request) {
        return postJsonAcceptJson(`${this.url}/publish/palette`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Theme {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/theme`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/theme`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/theme`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/theme`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/theme`, query, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/theme`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized client programs
 */
class Client {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        return postJsonAcceptJson(`${this.url}/edit/client`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client`, null, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/client`, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search clients assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class ClientToken {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client-token`, query, { requestHeaders: this.requestHeaders });
    }
}

class PartnerConnect {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async confirm(request) {
        return postJsonAcceptJson(`${this.url}/rpc/confirm-partner-connect`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class BrowserClient {
    constructor(context = {}) {
        this.currentAccount = new CurrentAccount(context);
        this.brand = new Brand(context);
        this.brandAlias = new BrandAlias(context);
        this.brandAttr = new BrandAttr(context);
        this.client = new Client(context);
        this.clientToken = new ClientToken(context);
        this.color = new Color(context);
        this.domain = new Domain(context);
        this.domainVerification = new DomainVerification(context);
        // this.file = new File(context);
        this.image = new Image(context);
        this.linkAccountUser = new LinkAccountUser(context);
        this.linkBrandImage = new LinkBrandImage(context);
        this.palette = new Palette(context);
        this.published = new Published(context);
        this.theme = new Theme(context);
        this.partnerConnect = new PartnerConnect(context);
    }
}

export default BrowserClient;

export {
    CurrentAccount,
    Client,
    ClientToken,
    Brand,
    BrandAlias,
    BrandAttr,
    Color,
    Domain,
    DomainVerification,
    // File,
    Image,
    LinkAccountUser,
    LinkBrandImage,
    Palette,
    Published,
    Theme,
    PartnerConnect,
};
